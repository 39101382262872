import axios from "@/plugins/axios";

const getAllowedCountries = () => {
  return axios.get("/api/v1/allowed-country-codes/");
};

const getNeighborhoods = (params) => {
  return axios.get(`/api/v1/neighborhoods/?${params}`);
};

const getCities = (params, country_id = "102358") => {
  return axios.get(`/api/v1/countries/${country_id}/cities/?${params || ''}`);
};

const getEmploymentTypes = () => {
  return axios.get(`/api/v1/employment-types/`);
};

const getPropertyTypes = () => {
  return axios.get(`/api/v1/nhc/property-types/`);
};

const getNationalities = () => {
  return axios.get(`/api/v1/simah/nationalities/`);
};

const getMaritalStatuses = () => {
  return axios.get(`/api/v1/simah/marital-statuses/`);
}

const getSimahCities = () => {
  return axios.get(`/api/v1/simah/cities/`);
}

export default {
  getAllowedCountries,
  getNeighborhoods,
  getCities,
  getEmploymentTypes,
  getPropertyTypes,
  getNationalities,
  getMaritalStatuses,
  getSimahCities
};
